<template>
  <div class="container">
    <div class="layout">
      <h1 style="text-align: center;">抖音神秘买手报告页</h1>
      <a-space style="width: 100%; margin-top: 80px;" direction="vertical" size="large" align="start">
        <div>
          <h2>购买凭证 + 物流签收</h2>
        </div>
        <a-image-preview-group v-if="info.purchaseVoucherUrl">
          <a-space :size="20">
            <a-image :width="180" :height="180" :src="info.purchaseVoucherUrl">
            </a-image>
            <a-image :width="180" :height="180" :src="info.logisticsSignUrl">
            </a-image>
          </a-space>
        </a-image-preview-group>
        <!-- <div>
          <h3>物流签收</h3>
        </div>
        <a-image-preview-group v-if="info.logisticsSignUrl">
          <a-space size="middle">
            <a-image :width="180" :height="180" :src="info.logisticsSignUrl">
            </a-image>
          </a-space>
        </a-image-preview-group> -->
        <div style="width: 100%; margin-top: 30px;">
          <h2>商品图片</h2>
        </div>
        <a-image-preview-group v-if="info.imgList">
          <div class="flex-box">
            <div v-for="item in info.imgList" :key="item.id" class="flex-box-child">
              <a-image :height="180" :width="180" :src="item.imgUrl">
              </a-image>
            </div>

          </div>
        </a-image-preview-group>
      </a-space>
      <a-space style="width: 100%; margin-top: 30px;" direction="vertical" size="large" align="start">

      </a-space>
      <a-space style="width: 100%; margin-top: 30px;" direction="vertical" size="large" align="start">

      </a-space>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { url } from '@/util/const'
  export default {
    name: 'App',
    components: {

    },
    data() {
      return {
        info: {

        }
      }
    },
    methods: {
      requestInfo() {
        // let url = "https://api.weijianapp.com"
        var getParams = new URL(window.location).searchParams
        const params = {
          taskId: getParams.get('taskId')
        }

        axios.post(url + '/muc/hypermarket/mysterious/getMysterioustokenPictureReport', params).then((res) => {
          if (res.data.code === '200') {
            console.log('res', res)
            this.info = res.data.data
          }
        })
      }
    },
    created() {
      this.requestInfo()
    }
  }
</script>

<style>
  .flex-box {
    display: flex;
    flex-wrap: wrap;

  }

  .flex-box-child {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    background: rgb(238, 238, 238);
  }

  .layout {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: white;
    min-height: 100vh;
    padding: 60px 30px 30px;
  }

  .ant-image {
    overflow: hidden;
    position: relative;
  }

  .ant-image-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
</style>